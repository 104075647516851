import * as React from "react"
import logo from '../assets/images/logo.png'
// markup
const IndexPage = () => {
  return (
    <a href="../assets/images/logo.png" target="_blank" rel="noopener noreferrer">
        <img src={logo} alt="Description of your image" />
    </a>
);
}

export default IndexPage
